.broadcast-form-session {
    &__form-wrapper {
        padding-top: 32px;
        padding-bottom: 40px;
        width: 752px;
        max-width: 100%;
        margin: 0 auto;
    }

    &__logo {
        margin: 0 auto;
        display: block;
        max-width: 400px;
        margin-bottom: 32px;
    }

    &__form, &__submission-received {
        background: #fff;
        border-radius: 8px;
    }

    &__submission-received {
        text-align: center;
        padding: 0px 20px 30px;

        img {
            width: 153px;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        h1 {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 50px;
            line-height: 1;
        }

        p {
            color: black;
            margin-bottom: 0;
            opacity: 0.66;
        }
    }

    &__header {
        padding: 40px 52px;
        border-bottom: 1px solid #d7d8e1;
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
        line-height: 1.45;
    }

    &__description {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        text-align: left;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }

    &__fieldset {
        padding: 28px 52px 24px 52px;
    }

    &__progressbar {
        margin-bottom: 24px;

        .progress-bar {
            background-color: var(--broadcast-form-brand-color, #0F4CDB);
        }
    }

    .content-length {
        margin-top: -33px;
    }

    .btn-primary-dynamic {
        --btn-primary-dynamic-color: var(--broadcast-form-brand-color, #0F4CDB);
    }

    .btn-secondary-dynamic {
        --btn-secondary-dynamic-color: var(--broadcast-form-brand-color, #0F4CDB);
    }

    &__submit-success,  &__submit-error {
        margin-top: -16px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0;
        padding-bottom: 30px;
        padding-left: 52px;
        padding-right: 52px;
    }

    &__error, &__info {
        text-align: center;
        font-weight: 500;
    }

    &__submit-success {
        color: green;
    }

    &__submit-error, &__error {
        color: red;
    }

    &__info {
        color: $vfInformation;
    }

    &__spinner {
        display: block;
        margin: 0 auto;
        width: 4rem;
        height: 4rem;
        border-width: 6px;
    }

    .form-control:focus {
        border-color: var(--broadcast-form-brand-color, #0F4CDB);
    }
    

    label.MuiFormControlLabel-root {
        margin-bottom: 0;
        margin-left: -6px;

        & [class*="PrivateSwitchBase-root"] {
            padding: 4px;
        }

        .MuiFormControlLabel-label {
            padding-left: 4px;
        }
    }

    .MuiRadio-colorPrimary.Mui-checked {
        color: var(--broadcast-form-brand-color, #0F4CDB);
    }

    .option-text-input {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .form-check {
        display: flex;
        align-items: center;
        height: 32px;
        padding-left: 0px;

        &-label {
            cursor: pointer;
            padding-left: 30px;
            line-height: 32px;
        }
        
        &-input {
            cursor: pointer;
            margin-left: 0;

            & + .form-check-icon {
                margin-left: 4px;
            }

            &:checked:after {
                background-color: var(--broadcast-form-brand-color, #0F4CDB);
            }
        }

    }

    &__question {
        margin-top: 28px;
        margin-bottom: 16px;
        font-weight: 500;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__footer {
        padding: 30px 52px 40px;
        display: flex;
        border-top: 1px solid #e5e7f2;
    }

    &__language_picker_div{
        float: right;
        padding-right: 8rem;
    }

    &__language_picker{
        width: 12rem;
        float: right;
    }

    &__language_picker_p{
        display: block;
        clear: both;
    }

    @media screen and (max-width: 576px) {
        &__header {
            padding-top: 28px;
            padding-bottom: 28px;
        }

        &__footer {
            padding-bottom: 30px;
        }

        &__spinner {
            width: 3rem;
            height: 3rem;
        }

        &__error, &__info {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}